<template>
  <div class="holder">
    <h1>
      严格保证两边tick数量一致，但不是整数
      <span @click="playIn0('refDoubleYBarLineChart0')">入场</span>
      <span @click="playOut('refDoubleYBarLineChart0')">出场</span>
    </h1>
    <double-y-bar-line-chart1 ref="refDoubleYBarLineChart0" id="refDoubleYBarLineChart0" customClass="c_line1" />

    <h1>
      柱折双Y轴专用图表，line在bar的后边，有line的面积
      <span @click="playIn1('doubleYBarLineChart')">入场</span>
      <span @click="playOut('doubleYBarLineChart')">出场</span>
    </h1>
    <double-y-bar-line-chart ref="doubleYBarLineChart" id="doubleYBarLineChart" customClass="c_line1" />

    <h1>
      数据比较少的情况，line和area在bar的前边，有line的点
      <span @click="playIn2('refDoubleYBarLineChart2')">入场</span>
      <span @click="playOut('refDoubleYBarLineChart2')">出场</span>
    </h1>
    <double-y-bar-line-chart ref="refDoubleYBarLineChart2" id="refDoubleYBarLineChart2" customClass="c_line2" />

    <svg width="300" height="300" class="svg_gradient">
      <defs>
        <linearGradient id="barPositive" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="#05a" />
          <stop offset="100%" stop-color="#0a5" />
        </linearGradient>
        <linearGradient id="barNegative" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="#F51313" />
          <stop offset="100%" stop-color="C60909" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>
<script>
import doubleYBarLineChart1 from "@/components/marketChart/doubleYBarLineChart.vue";
import doubleYBarLineChart from "@/components/marketChart/doubleYBarLineChart_v2.0.vue";
import data from "./data.json";
import data2 from "./data2.json";
export default {
  data: function () {
    return {};
  },
  components: { doubleYBarLineChart1, doubleYBarLineChart },
  methods: {
    playIn0(refName) {
      this.$refs[refName].doubleYBarLineChart(data, {
        width: 600,
        height: 500,
        marginLeft: 60,
        marginRight: 65,
        marginTop: 50,
        marginBottom: 80,
        duration: 1000,
        xPadding: 0.3,
        ease: "easeCubicIn",
        xTickNumber: 4,
        yTickNumber: 5,
        isLineAboveBar: false,
      });
    },
    playIn1(refName) {
      this.$refs[refName].doubleYBarLineChart(data, {
        width: 600,
        height: 500,
        marginLeft: 60,
        marginRight: 65,
        marginTop: 50,
        marginBottom: 80,
        duration: 1000,
        xPadding: 0.3,
        ease: "easeCubicIn",
        xTickNumber: 4,
        yTickNumber: 5,
        isLineAboveBar: false,
      });
    },
    playIn2(refName) {
      this.$refs[refName].doubleYBarLineChart(data2, {
        width: 600,
        height: 500,
        marginLeft: 60,
        marginRight: 65,
        marginTop: 50,
        marginBottom: 80,
        duration: 1000,
        xPadding: 0.3,
        ease: "easeCubicIn",
        xTickNumber: 5,
        yTickNumber: 5,
        isLineAboveBar: true,
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h1 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h2 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  .new {
    color: #fff;
    background: #f00;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    margin-right: 0.625rem;
  }
}
p {
  margin-bottom: 20px;
}

.c_line1 {
  margin-bottom: 20px;
  min-height: 200px;
  background: #fefefe;
  background: -webkit-linear-gradient(top right, #fefefe, #ccc);
  background: -moz-linear-gradient(top right, #fefefe, #ccc);
  background: linear-gradient(to bottom left, #fefefe, #ccc);
  display: inline-block;
  /deep/ .axis_x {
    .text {
      font-size: 14px;
    }
  }
  /deep/ .axis_y {
    .text {
      font-size: 14px;
    }
  }
  /deep/ .bar_negative {
    fill: #47e60e;
  }
  /deep/ .bar_positive {
    fill: #e62c0e;
  }
  /deep/ .line {
    stroke: #178cea;
  }
  /deep/ .area_top {
    stop-color: #178cea;
    stop-opacity: 0.42;
  }
  /deep/ .area_bottom {
    stop-color: #178cea;
    stop-opacity: 0.26;
  }
  /deep/ .bar {
    fill: #ffc100;
  }
}
.c_line2 {
  margin-bottom: 20px;
  min-height: 200px;
  background: #fefefe;
  background: -webkit-linear-gradient(top right, #fefefe, #ccc);
  background: -moz-linear-gradient(top right, #fefefe, #ccc);
  background: linear-gradient(to bottom left, #fefefe, #ccc);
  display: inline-block;
  /deep/ .axis_x {
    .text {
      font-size: 14px;
    }
  }
  /deep/ .axis_y {
    .text {
      font-size: 14px;
    }
  }
  /deep/ .bar_positive {
    fill: url(#barPositive);
  }
  /deep/ .bar_negative {
    fill: url(#barNegative);
  }
  /deep/ .line {
    stroke: #178cea;
  }
  /deep/ .line_area {
    display: none;
  }
  /deep/ .area_top {
    stop-color: #178cea;
    stop-opacity: 0.42;
  }
  /deep/ .area_bottom {
    stop-color: #178cea;
    stop-opacity: 0.26;
  }
}
</style>
