<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotal: 0,
      svgLineTotalArr: [],
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async doubleYBarLineChart(
      data,
      {
        label = (d) => d.label,
        line = (d) => d.line,
        bar = (d) => d.bar,
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 80, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 60, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain,
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain1,
        yDomain2,
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        xPadding = 0.3, // 柱子的比例
        duration = 400, //动画持续时长
        ease = "easeCubicIn", //元素之间间隔时长
        xTickNumber = 3,
        yTickNumber = 7,
        isLineAboveBar = true, //默认线在柱子上
        dotCircleRadius = 5,
      } = {}
    ) {
      const X = d3.map(data, label);
      const Bar = d3.map(data, bar);
      const Line = d3.map(data, line);

      //x的数值范围
      if (xDomain === undefined) xDomain = X;
      //bar的数值范围
      if (yDomain1 === undefined) yDomain1 = [d3.min(Bar), d3.max(Bar)];
      //line的数值范围
      if (yDomain2 === undefined) yDomain2 = [d3.min(Line), d3.max(Line)];

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale1 = d3.scaleLinear(yDomain1, yRange);
      const yScale2 = d3.scaleLinear(yDomain2, yRange);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //画xy轴
      const drawAxis = () => {
        const xArr = [];
        if (xTickNumber == 1) {
          let index = parseInt(data.length / 2);
          xArr.push(xScale.domain()[index]);
        } else if (xTickNumber == 2) {
          xArr.push(xScale.domain()[0]);
          xArr.push(xScale.domain()[data.length - 1]);
        } else if (xTickNumber > 2) {
          for (let i = 0; i < xTickNumber; i++) {
            let index = Math.ceil(i * ((data.length - 1) / (xTickNumber - 1)));
            index = index > data.length - 1 ? data.length - 1 : index;
            xArr.push(xScale.domain()[index]);
          }
        }
        const xAxis = d3.axisBottom(xScale).tickSizeOuter(0).tickValues(xArr);
        const axisX = svg
          .append("g")
          .attr("class", "axis_x")
          .attr("transform", `translate(0,${height - marginBottom})`)
          .call(xAxis)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
            g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
          });
        axisX.selectAll(".domain").transition().duration(400).attr("opacity", 1);
        axisX.selectAll(".tick_line").transition().duration(400).attr("opacity", 1);
        axisX
          .selectAll(".text")
          .transition()
          .delay(function (d, i) {
            return i * 30;
          })
          .ease(d3[ease])
          .duration(300)
          .attr("opacity", 1);

        const yAxis1 = d3.axisLeft(yScale1).ticks(yTickNumber).tickSizeOuter(0);
        const axisY1 = svg
          .append("g")
          .attr("class", "axis_y")
          .attr("transform", `translate(${marginLeft},0)`)
          .call(yAxis1)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
            g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
          });
        axisY1.selectAll(".domain").transition().duration(400).attr("opacity", 1);
        axisY1.selectAll(".tick_line").transition().duration(400).attr("opacity", 1);
        axisY1
          .selectAll(".text")
          .transition()
          .delay(function (d, i) {
            return i * 30;
          })
          .ease(d3[ease])
          .duration(300)
          .attr("opacity", 1);

        const yAxis2 = d3.axisRight(yScale2).ticks(yTickNumber).tickSizeOuter(0);
        const axisY2 = svg
          .append("g")
          .attr("class", "axis_y")
          .attr("transform", `translate(${width - marginRight},0)`)
          .call(yAxis2)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
            g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
          });
        axisY2.selectAll(".domain").transition().duration(400).attr("opacity", 1);
        axisY2.selectAll(".tick_line").transition().duration(400).attr("opacity", 1);
        axisY2
          .selectAll(".text")
          .transition()
          .delay(function (d, i) {
            return i * 30;
          })
          .ease(d3[ease])
          .duration(300)
          .attr("opacity", 1);
      };
      drawAxis();

      //画柱子
      const barDuration = 300;
      const barDelay = (duration - barDuration) / (data.length - 1);
      const drawBar = () => {
        const barGroup = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(Bar)
          .enter()
          .append("rect")
          .attr("x", (d, i) => xScale(X[i]))
          .attr("y", (d, i) => yScale1(0))
          .attr("width", (d, i) => xScale.bandwidth())
          .attr("height", 0)
          .attr("class", (d, i) => {
            return `bar ${d > 0 ? "bar_positive" : "bar_negative"}`;
          })
          .transition()
          .delay((d, i) => i * barDelay)
          .duration(barDuration)
          .ease(d3[ease])
          .attr("y", (d, i) => (d < 0 ? yScale1(0) : yScale1(d)))
          .attr("height", (d, i) => Math.abs(yScale1(0) - yScale1(d)));
      };

      //画线
      const drawLine = async () => {
        const lineGroup = svg
          .append("g")
          .attr("class", "line_group")
          .attr("transform", "translate(" + xScale.bandwidth() / 2 + ",0)");
        const pathLine = d3
          .line()
          .curve(d3[curveName])
          .x((i) => xScale(X[i]))
          .y((i) => yScale2(Line[i]));
        const svgLine = lineGroup
          .append("path")
          .attr("fill", "none")
          .attr("class", `line`)
          .attr("stroke", "black")
          .attr("d", pathLine(d3.range(Line.length)));
        const svgLineTotalLength = svgLine.node().getTotalLength();
        svgLine
          .attr("stroke-dasharray", svgLineTotalLength + "," + svgLineTotalLength)
          .attr("stroke-dashoffset", svgLineTotalLength)
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .attr("stroke-dashoffset", 0);
        // }
      };

      //画线上的dot
      const drawLineDot = async () => {
        const dotCircle = svg
          .append("g")
          .attr("class", `dot_circle_group`)
          .selectAll("circle")
          .data(Line)
          .join("circle")
          .attr("cx", (d, i) => xScale.bandwidth() / 2 + xScale(X[i]))
          .attr("cy", (d) => yScale2(d))
          .attr("r", dotCircleRadius * 0.1)
          .attr("class", "dot")
          .attr("fill", "#f00")
          .attr("opacity", "0");
        dotCircle
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .delay((d, i) => i * 30)
          .attr("r", dotCircleRadius)
          .attr("opacity", "1");
      };

      //画面积
      const drawArea = () => {
        const area = d3
          .area()
          .x(function (d, i) {
            return xScale(d.label);
          })
          .y0(height - marginBottom) //定义y0轴
          .y1(function (d) {
            return yScale2(d.line);
          }); //对y1轴进行缩放

        //画渐变
        const areaGroup = svg
          .append("g")
          .attr("class", "area_group")
          .attr("transform", "translate(" + xScale.bandwidth() / 2 + ",0)");
        const defs = areaGroup.append("defs");
        const linearGradient = defs
          .append("linearGradient")
          .attr("id", "linearColor")
          .attr("x1", "0%")
          .attr("y1", "0%")
          .attr("x2", "0%")
          .attr("y2", "100%");
        linearGradient.append("stop").attr("class", "area_top").attr("offset", "0%");

        linearGradient.append("stop").attr("class", "area_bottom").attr("offset", "100%");

        //画区域图
        areaGroup
          .append("path")
          .attr("d", area([data[0]]))
          .attr("class", "line_area")
          .attr("fill", "url(#" + linearGradient.attr("id") + ")")
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .attrTween("d", () => {
            let index = d3.interpolate(0, data.length - 1);
            return (t) => {
              if (t <= 0) return area([data[0]]);
              let deg = Math.floor(index(t));
              return area(data.slice(0, deg + 1));
            };
          });
      };

      if (isLineAboveBar) {
        drawBar();
        drawLine();
        drawLineDot();
      } else {
        drawLine();
        drawArea();
        drawBar();
      }

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
